<template>
  <div id="center">
	<div class="title_bg">
	<p>链上数据</p>
	</div>
    <div class="up">
		<div class="bag">
		<img src="../assets/bg.png">
		<div class="bg-lss items" v-for="item in titleItems" :key="item.title">
		<p class="name">{{ item.title }}</p>
		<img src="../assets/xian.png" style="margin-top: 8px;">
		<div >
		<dv-digital-flop class="num3" :config="item.number" />
		</div>
		</div>
		</div>	
      <div class="bg-ls item" v-for="item in titleItem" :key="item.title">
        <p class="name">{{ item.title }}</p>
		<img src="../assets/xian2.png" style="margin-top: 8px;">
        <div >
			<dv-digital-flop class="num" :config="item.number" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		titleItems: [
		{
		title: '游戏资产包',
		number: {
		number: [46],
		textAlign: 'left',
		content: '{nt}',
		style: {
		fontSize: 20,
		fill:'#ffffff'
		}
		}
		},
		],
      titleItem: [
			{
			title: '发行数据',
			number: {
			number: [9214],
			textAlign: 'left',
			content: '{nt}',
			style: {
			fontSize: 20,
			fill:'#ffffff'
			}
			}
			},
        {
          title: '研发数据',
          number: {
            number: [3652],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 20,
fill:'#ffffff'
            }
          }
        },
        {
          title: '产权数据',
          number: {
            number: [7765],
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 20,
fill:'#ffffff'
            }
          }
        },

      
      ],
    
    }
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
	.title_bg{
	width: 400px;
	height: 40px;
	background-image: url("../assets/title_bg.png");
	background-size: cover;
	background-position: center center;
	line-height: 40px;
	margin-bottom: 16px;
	p{
	margin-left: 32px;
	font-size: 19px;
	background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
	-webkit-background-clip: text;
	color: transparent;
	font-weight: bold;
	}
	}
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
	height: 229px;
	background-image: url("../assets/ls_bg.png");
	background-size: cover;
	background-position: center center;
	align-items: center;
	.bag{
		width: 100%;
		display: flex;
		justify-content: space-around;
		.bg-lss{
			background-image: url("../assets/bg2.png");
			background-size: cover;
			background-position: center center;
		}
		.items{
			width: 274px;
			height: 84px;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 7px;
			.name{
				margin-top: 10px;
				color: #99e5fb;
				font-size: 14px;
			}
			.num3 {
				width: 150px;
				height: 30px;
				margin-top: 10px !important;
				margin-left: 130px;
				color: #ffffff;
				font-size: 19px;
				}
		}
	}
	
	.bg-ls {
		background-image: url("../assets/sj.png");
		background-size: cover;
		background-position: center center;
	}
	.item {
	width: 118px;
	height: 84px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	.name{
		margin-top: 10px;
		color: #99e5fb;
		font-size: 14px;
	}
	.num {
		width: 150px;
		height: 30px;
		margin-top: 6px;
		margin-left: 110px;
		color: #ffffff;
		font-size: 19px;
		}
	}
	.num3 {
		width: 150px;
		height: 30px;
		margin-top: 15px;
		margin-left: 130px;
		color: #ffffff;
		font-size: 19px;
		}
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
   
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
