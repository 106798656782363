import { render, staticRenderFns } from "./left1.vue?vue&type=template&id=c8100cb4&scoped=true"
import script from "./left1.vue?vue&type=script&lang=js"
export * from "./left1.vue?vue&type=script&lang=js"
import style0 from "./left1.vue?vue&type=style&index=0&id=c8100cb4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8100cb4",
  null
  
)

export default component.exports