<template>
  <div id="centerRight2">
	<div class="title_bg">
	<p>资金</p>
	</div>
   <div class="bg-r2">
      <div class="d-flex flex-column body-box">
        <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
		<div class="d-flex flex-column zijin">
			<div class="d-flex flex-wrap flex-end"><p class="t">首期资金</p><img src="../assets/n11.png"/><p style="font-size: 14px;margin-left: 12px;">万元</p></div>
			<div style="margin-top: 24px;" class="d-flex flex-wrap flex-end"><p class="t">可投资金</p><img src="../assets/n22.png"/><p style="font-size: 14px;margin-left: 12px;">万元</p></div>
		</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      config: {
        data: [
          {
            name: '物华弥新',
            value: 120
          },
          {
            name: '下一站江湖2',
            value: 90
          },
          {
            name: '萌宠拆拆乐',
            value: 86
          },
          {
            name: '梦灯花',
            value: 71
          },
          {
            name: '匠木',
            value: 65
          }
        ],
		// colors: ['#009dff', '#24A5F6', '#58C9F5', '#32c5e9', '#96bfff'],
		unit: '热度',
		showValue: true
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-height: 205px;
  $box-width: 454px;
  height: $box-height;
  width: $box-width;
  
  .zijin{
		padding: 20px 32px;
      font-size: 14px;
      color: #9ce9ff;
      line-height: 1.8;
		width: 100%;
		height: 151px;
		background-image: url("../assets/zj_bg.png");
		background-size: cover;
		background-position: center center;
		.t{
			margin-right: 26px;
		}
  }
	
  
	.title_bg{
	width: 400px;
	height: 40px;
	background-image: url("../assets/title_bg.png");
	background-size: cover;
	background-position: center center;
	line-height: 40px;
	margin-bottom: 16px;
	p{
	margin-left: 32px;
	font-size: 19px;
	background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
	-webkit-background-clip: text;
	color: transparent;
	font-weight: bold;
	}
	}
  .text {
    color: #c3cbde;
  }
  .body-box {
    overflow: hidden;
    .dv-cap-chart {
      
    }
  }
}
</style>
