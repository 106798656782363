<template>
  <div id="centerRight1">
	<div class="title_bg">
	<p>产品</p>
	</div>
	<div class="bg-cp">
		<div class="d-flex jc-center colorWhite">进行中</div>
      <div class="d-flex jc-center body-box">
        <!-- <dv-scroll-board class="dv-scr-board" :config="config" /> -->
		<div class="game">
			<div class="games">
				<img class="game_img" src="../assets/g1.png"/>
				<div class="game_name">
					<p class="rank1">NO.1</p>
					<p>群英风华录</p>
				</div>
			</div>
			<div class="games">
				<img class="game_img" src="../assets/g2.jpg"/>
				<div class="game_name">
					<p class="rank2">NO.2</p>
					<p>江湖如梦</p>
				</div>
			</div>
			<div class="games">
				<img class="game_img" style="background-color: #ffffff;" src="../assets/g3.png"/>
				<div class="game_name" >
					<p class="rank3">NO.3</p>
					<p>下一站江湖2</p>
				</div>
			</div>
			<div class="games">
				<img class="game_img" src="../assets/g4.png"/>
				<div class="game_name">
					<p class="rank4">NO.4</p>
					<p>谍：惊蛰</p>
				</div>
			</div>
		</div>
      </div>
		<div class="d-flex ai-center  body-box">
		<!-- <dv-capsule-chart class="dv-cap-chart" :config="config1" /> -->
		<dv-scroll-ranking-board :config="config1" class="dv-cap-chart"/>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        // header: ['进行中'],
        data: [
			["<div class='d-flex'><p class='shu1'></p><p class='bg1'><span class='cp_num'>1</span>物华弥新</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg2'><span class='cp_num'>2</span>下一站江湖2</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg1'><span class='cp_num'>3</span>萌宠拆拆乐</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg2'><span class='cp_num'>4</span>梦灯花</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg1'><span class='cp_num'>5</span>匠木</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg2'><span class='cp_num'>6</span>二十四节气</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg1'><span class='cp_num'>7</span>奇门之上</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg2'><span class='cp_num'>8</span>武侠塔防战</p></div>"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 24,
        headerBGC: 'none', //表头
        oddRowBGC: 'none', //奇数行
        evenRowBGC: 'none', //偶数行
        index: false,
        align: ['start'],
		waitTime:2000
      },
		config1: {
		data: [
		{
		name: '群英风华录',
		value: 160
		},
		{
		name: '江湖如梦',
		value: 130
		},
		{
		name: '下一站江湖2',
		value: 120
		},
		{
		name: '谍：惊蛰',
		value: 116
		},
		{
		name: '无限机兵',
		value: 110
		},
		{
		name: '铸剑阁',
		value: 108
		},
		
		{
		name: '觉醒异仞',
		value: 98
		},{
		name: '出发吧诗词',
		value: 92
		},{
		name: '繁华三千集',
		value: 85
		},{
		name: '晨昏线',
		value: 81
		},{
		name: '三国',
		value: 76
		},{
		name: '铃兰计划',
		value: 70
		},{
		name: '锦衣卫',
		value: 67
		},{
		name: '青城',
		value: 66
		},{
		name: '忍者驴',
		value: 61
		},{
		name: '江湖',
		value: 55
		},{
		name: '幻想名将录2',
		value: 53
		},{
		name: 'LAB',
		value: 52
		},{
		name: '天之禁',
		value: 48
		},{
		name: '可乐喵',
		value: 43
		},{
		name: '拼图大师',
		value: 41
		},{
		name: '黑白守卫战',
		value: 38
		},{
		name: '武侠塔防战',
		value: 35
		},{
		name: '王牌漂移',
		value: 32
		},{
		name: '好玩自走棋',
		value: 30
		},{
		name: '君临传奇',
		value: 29
		},{
		name: '克隆世界',
		value: 28
		},{
		name: '远星计划',
		value: 25
		},{
		name: '一念剑歌',
		value: 24
		},
		{
		name: '猿公剑',
		value: 20
		},
		{
		name: '归原田居',
		value: 18
		}
		],
		carousel: 'single',
		// colors: ['#009dff', '#24A5F6', '#58C9F5', '#32c5e9', '#96bfff'],
		unit: '热度',
		showValue: true,
		waitTime:1600
		}
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 500px;
$box-width: 456px;
#centerRight1 {
  // padding: 16px;
  // padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  margin-top: 15px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
	overflow: hidden;
	.dv-scr-board {
	width: 424px;
	height: 160px;
	margin-left: 18px;
	}
  }
	.title_bg{
		width: 400px;
		height: 40px;
		background-image: url("../assets/title_bg.png");
		background-size: cover;
		background-position: center center;
		line-height: 40px;
		margin-bottom: 12px;
		p{
			margin-left: 32px;
			font-size: 19px;
			background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
			-webkit-background-clip: text;
			color: transparent;
			font-weight: bold;
		}
	}
	.bg-cp{
		height: 445px;
		width: $box-width;
		background-image: url("../assets/cp_bg2.png");
		background-size: cover;
		background-position: center center;
	}
	.colorWhite{
		line-height: 40px !important;
		height: 30px !important;
		font-size: 14px !important;
		color: #a2d9ff !important;
	}
	.body-box {
	overflow: hidden;
	.dv-cap-chart {
		width: 404px;
		height: 210px;
		margin: 0 auto;
		margin-top: 35px;
		.row-item .ranking-info .info-name{
			color: #fe8c30 !important;
		}
		>.dv-scroll-ranking-board .ranking-column .inside-column{
			background-color: #ffffff !important;
		}
	}
	}
	
	.game{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap:wrap;
		width: 408px;
		.games{
			width: 180px;
			margin-top: 16px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.game_img{
				width: 62px;
				height: 62px;
				border: 1px solid #37c9ff;
				border-radius: 10px;
			}
			.game_name{
				width: 118px;
				height: 50px;
				margin-left: 8px;
				background-color: #043a6c;
				border-radius: 5px;
				padding: 8px 15px;
				font-size: 14px;
				.rank1{
					color: #fe8c30;
					font-weight: bold;
					font-style: italic;
				}
				.rank2{
					color: #f8c927;
					font-weight: bold;
					font-style: italic;
				}
				.rank3{
					color: #4becff;
					font-weight: bold;
					font-style: italic;
				}
				.rank4{
					color: #36b6ff;
					font-weight: bold;
					font-style: italic;
				}
			}
			
		}
		
	}
	
    
}
</style>
